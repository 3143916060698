<template>
  <v-card
    class="pa-5 my-3 primary-border"
    rounded="lg"
    flat
    color="transparent"
  >
    <v-row dense>
      <v-col
        v-if="tableIndex > 0"
        cols="12"
        md="12"
      >
        <v-btn
          @click="$emit('removeTable')"
          color="primary"
          class="mr-2"
          dense
          rounded
          outlined
        >
          Remover tabela
          <v-icon
            class="ml-2"
            small
            >fas fa-trash</v-icon
          >
        </v-btn>
      </v-col>

      <v-col
        v-if="tableIndex > 0"
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="costTable.title"
          label="Nome da tabela do Fornecedor*"
          placeholder="Nome da tabela"
          :rules="[() => !!costTable.title || 'Este campo é obrigatório']"
          outlined
          rounded
          dense
          hide-details
          @input="$emit('update:costTable', costTable)"
        />
      </v-col>
      <v-col
        v-else
        class="pb-4"
        cols="12"
        md="2"
      >
        <v-switch
          v-model="costTable.is_fixed"
          :disabled="costTable.rows.length > 1"
          class="mt-0"
          label="Custo fixo"
          inset
          hide-details
          @change="$emit('update:costTable', costTable)"
        />
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <CostTableInputs
          v-for="(row, idx) in costTable.rows"
          :key="idx"
          :row="row"
          :rows="costTable.rows"
          @removeRow="removeRow(row)"
          :option="true"
          :isFixed="costTable.is_fixed"
          :tableIndex="tableIndex"
        />
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <v-btn
          v-show="!costTable.is_fixed"
          @click="addRow"
          icon
          dark
          class="primary"
        >
          <v-icon small>fas fa-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    costTable: {
      type: Object,
      required: true,
    },
    print_types: Array,
    tableIndex: Number,
  },
  components: {
    CostTableInputs: () => import('./CostTableInputs'),
  },
  data: () => ({
    printTypes: [],
  }),
  methods: {
    removeRow(row) {
      const idx = this.costTable.rows.indexOf(row)
      if (idx > -1) {
        this.costTable.rows.splice(idx, 1)
        this.$emit('update:costTable', this.costTable)
      }
    },
    addRow() {
      this.costTable.rows.push({})
      this.$emit('update:costTable', this.costTable)
    },
    getPrints() {
      this.$api
        .get('product_prints/products')
        .then((res) => {
          this.print_types.forEach((print) => {
            const found = res.data.data.find((value) => value.id === print)
            if (found) {
              this.printTypes.push(found)
            }
          })
          console.log(this.printTypes)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    // Define o valor padrão de is_fixed somente para a primeira tabela
    if (
      this.tableIndex === 0 &&
      typeof this.costTable.is_fixed === 'undefined'
    ) {
      this.$set(this.costTable, 'is_fixed', true)
    } else if (
      this.tableIndex > 0 &&
      typeof this.costTable.is_fixed === 'undefined'
    ) {
      this.$set(this.costTable, 'is_fixed', false)
    }
    this.getPrints()
  },
}
</script>

<style scoped>
.primary-border {
  border: 1px solid var(--v-primary-base) !important;
}
</style>
